import React from "react";

// import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import AuthUser from "components/Sb/AuthUser";

// slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// @material-ui/icons
import Code from "@material-ui/icons/Code";
import VerifiedUser from "@material-ui/icons/VerifiedUser";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

// importing css
import "assets/css/common.css";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
// import SuccessFulll from "./SuccessFulll";
const useStyles = makeStyles(styles);

// images
import partner1 from "assets/img/otp-logo/partner1.png";
import partner2 from "assets/img/otp-logo/partner2.png";
import partner3 from "assets/img/otp-logo/partner3.png";
import partner4 from "assets/img/otp-logo/partner4.png";
import partner5 from "assets/img/otp-logo/partner5.png";
import partner6 from "assets/img/otp-logo/partner6.png";
import partner7 from "assets/img/otp-logo/partner7.png";
import partner8 from "assets/img/otp-logo/partner8.png";
import partner9 from "assets/img/otp-logo/partner9.png";
import partner10 from "assets/img/otp-logo/partner10.png";
import partner11 from "assets/img/otp-logo/partner11.png";
import partner12 from "assets/img/otp-logo/partner12.png";
import partner13 from "assets/img/otp-logo/partner13.png";
import partner14 from "assets/img/otp-logo/partner14.png";
import partner15 from "assets/img/otp-logo/partner15.png";
import partner16 from "assets/img/otp-logo/partner16.png";
import partner17 from "assets/img/otp-logo/partner17.png";
import partner18 from "assets/img/otp-logo/partner18.png";
import partner19 from "assets/img/otp-logo/partner19.png";
import partner20 from "assets/img/otp-logo/partner20.png";
import partner21 from "assets/img/otp-logo/partner21.png";
import partner22 from "assets/img/otp-logo/partner22.png";
import partner23 from "assets/img/otp-logo/partner23.png";
import partner24 from "assets/img/otp-logo/partner24.png";
import partner25 from "assets/img/otp-logo/partner25.png";
import partner26 from "assets/img/otp-logo/partner26.png";
import partner27 from "assets/img/otp-logo/partner27.png";
import partner28 from "assets/img/otp-logo/partner28.png";
import partner29 from "assets/img/otp-logo/partner29.png";
import partner30 from "assets/img/otp-logo/partner30.png";
import partner31 from "assets/img/otp-logo/partner31.png";
import partner32 from "assets/img/otp-logo/partner32.png";
import partner33 from "assets/img/otp-logo/partner33.png";
import partner34 from "assets/img/otp-logo/partner34.png";
import partner35 from "assets/img/otp-logo/partner35.png";
import partner36 from "assets/img/otp-logo/partner36.png";
import partner37 from "assets/img/otp-logo/partner37.png";
import partner38 from "assets/img/otp-logo/partner38.png";
import partner39 from "assets/img/otp-logo/partner39.png";
import partner40 from "assets/img/otp-logo/partner40.png";
import partner41 from "assets/img/otp-logo/partner41.png";
import partner42 from "assets/img/otp-logo/partner42.png";
import partner43 from "assets/img/otp-logo/partner43.png";
import partner44 from "assets/img/otp-logo/partner44.png";
import partner45 from "assets/img/otp-logo/partner45.png";
import partner46 from "assets/img/otp-logo/partner46.png";
import partner47 from "assets/img/otp-logo/partner47.png";
import partner48 from "assets/img/otp-logo/partner48.png";
import partner49 from "assets/img/otp-logo/partner49.png";
import partner50 from "assets/img/otp-logo/partner50.png";
import partner51 from "assets/img/otp-logo/partner51.png";
import partner52 from "assets/img/otp-logo/partner52.png";
import partner53 from "assets/img/otp-logo/partner53.png";
import partner54 from "assets/img/otp-logo/partner54.png";
import partner55 from "assets/img/otp-logo/partner55.png";
import partner56 from "assets/img/otp-logo/partner56.png";
import partner57 from "assets/img/otp-logo/partner57.png";
import partner58 from "assets/img/otp-logo/partner58.png";
import partner59 from "assets/img/otp-logo/partner59.png";
import partner60 from "assets/img/otp-logo/partner60.png";
import partner61 from "assets/img/otp-logo/partner61.png";
import partner62 from "assets/img/otp-logo/partner62.png";
import partner63 from "assets/img/otp-logo/partner63.png";
import partner64 from "assets/img/otp-logo/partner64.png";
import partner65 from "assets/img/otp-logo/partner65.png";
import partner66 from "assets/img/otp-logo/partner66.png";
import partner67 from "assets/img/otp-logo/partner67.png";
import partner68 from "assets/img/otp-logo/partner68.png";

const OTPVerify = (props) => {
  const { handleToggleOne } = props;
  const classes = useStyles();
  const { http } = AuthUser();
  const [email, setEmail] = React.useState("")
  const [phone, setPhone] = React.useState("")

  // OTP
  const [otp, setOtp] = React.useState("");
  const [otpSent, setOtpSend] = React.useState(false);
  const [formError, setFormError] = React.useState({});
  let formErr = {};

  //getting otp by connecting on api
  React.useEffect(() => {
    http.get("/profile")
        .then((response) => {
            if (response.status === 200) {
                  const data = response.data
                  setEmail(data.email ? data.email : "NA")
                  setPhone(data.phone ? data.phone : "NA")
              }
          })
          .catch((err) => {
              console.log(err)
          })
  }, [])

  React.useState(() => {
    http
      .get("/customer/getOnboardingstep")
      .then((res) => {
        if (res.status == 200) {
          const step = res.data;
          if (step < 2) {
            http
              .get("/customer/sentOtp")
              .then((response) => {
                if (response.data == true) {
                  setOtpSend(true);
                  toast.success(
                    "OTP is send to registed Email address & Mobile number",
                    {
                      position: toast.POSITION.TOP_CENTER,
                      autoClose: 4000,
                    }
                  );
                }
              })
              .catch((err) => {
                toast.error(`Something went wrong! ${err.message}`, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 1500,
                });
              });
          }
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, []);

  // OTP form submit and error
  const handleFormError = () => {
    const verifyNumber = (value) => {
      var numberRex = new RegExp("^[0-9]+$");
      if (numberRex.test(value)) {
        return true;
      }
      return false;
    };
    if (otp.trim().length === 0 || !verifyNumber(otp)) {
      formErr.otp = "Error";
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
      toast.error("Your OTP is Mismatch!", {
        position: toast.POSITION.TOP_CENTER,
        //onClose: () => history.push('customer/user-page'),
        autoClose: 1500,
      });
    } else {
      const data = { otp };

      http
        .post("/customer/otpVerification", data)
        .then((res) => {
          if (res.status === 200) {
            toast.success("OTP has been verified successfully", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1500,
            });

            setFormError({});
            handleToggleOne();
            setOtp("");
          }
          if (res.status === 210) {
            toast.warn("OTP has been Mistach", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1500,
            });
          }
        })
        .catch((err) => {
          toast.error(`Oops Something went wrong! ${err.message}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
        });
    }
  };

  // slick
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1284,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slickData = [
    partner1,
    partner2,
    partner3,
    partner4,
    partner5,
    partner6,
    partner7,
    partner8,
    partner9,
    partner10,
    partner11,
    partner12,
    partner13,
    partner14,
    partner15,
    partner16,
    partner17,
    partner18,
    partner19,
    partner20,
    partner21,
    partner22,
    partner23,
    partner24,
    partner25,
    partner26,
    partner27,
    partner28,
    partner29,
    partner30,
    partner31,
    partner32,
    partner33,
    partner34,
    partner35,
    partner36,
    partner37,
    partner38,
    partner39,
    partner40,
    partner41,
    partner42,
    partner43,
    partner44,
    partner45,
    partner46,
    partner47,
    partner48,
    partner49,
    partner50,
    partner51,
    partner52,
    partner53,
    partner54,
    partner55,
    partner56,
    partner57,
    partner58,
    partner59,
    partner60,
    partner61,
    partner62,
    partner63,
    partner64,
    partner65,
    partner66,
    partner67,
    partner68,
  ];

  return (
    <GridContainer justify="center">
      {/* OTP */}
      <GridItem xs={12} sm={12} md={10} lg={10}>
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <VerifiedUser />
              </CardIcon>
              <h4 className="card-title">OTP verification</h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8} lg={8}>
                  <h5 style={{ textAlign: "center" }}>
                    verify your details to proceed further & get connected with
                    Top rated Industry Experts for your requirement
                  </h5>
                </GridItem>
                {/* OTP */}
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <div className="input-margin">
                    <CustomInput
                      success={otp.length > 0 ? true : false}
                      error={
                        Object.keys(formError).length > 0 && formError.otp
                          ? true
                          : false
                      }
                      labelText={
                        <span>
                          OTP Verfication <small>(required)</small>
                        </span>
                      }
                      id="otp"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        name: "otp",
                        value: otp,
                        type: "number",
                        onChange: (event) => {
                          setOtp(event.target.value);
                          setFormError({});
                        },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            className={classes.inputAdornment}
                          >
                            <Code className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </GridItem>
                {/* note */}
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <div style={{ textAlign: "center", marginBottom: "12px" }}>
                    <span>
                      Note : Check your registered mobile number & email id for
                      OTP
                    </span>
                    <p className="gtag_email">{email}</p>
                    <p className="gtag_phone">{phone}</p>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {otpSent ? (
                      <Button
                        color="info"
                        className={classes.registerButton}
                        type="submit"
                        round
                      >
                        Submit OTP
                      </Button>
                    ) : (
                      <Button
                        color="danger"
                        className={classes.registerButton}
                        round
                      >
                        Please wait sending the OTP
                      </Button>
                    )}
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </form>
      </GridItem>
      {/* logos */}
      <GridItem xs={12} sm={12} md={10} lg={10}>
        <Card>
          <CardHeader color="warning" icon>
            <CardIcon color="warning">
              <VerifiedUser />
            </CardIcon>
            <h4 className="card-title">Our Trusted Members</h4>
          </CardHeader>
          <CardBody>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={12} sm={12} md={6} lg={4}>
                <h2 className="manufacturing-industry-container">
                  SolutionBuggy is Trusted by 1,00,000+ Manufacturing Industry
                  across India
                </h2>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={8}>
                <Slider {...settings}>
                  {slickData.map((data, i) => {
                    return (
                      <div key={i}>
                        <img
                          src={data}
                          alt="try"
                          width={"160px"}
                          height="auto"
                          style={{ margin: "6px" }}
                        />
                      </div>
                    );
                  })}
                </Slider>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

OTPVerify.propTypes = {
  handleToggleOne: PropTypes.func,
};

export default OTPVerify;
